import Phaser from "phaser";
import LoadingScene from "@/games/TowerClimbing/scenes/LoadingScene";
import PlayScene from "@/games/TowerClimbing/scenes/PlayScene";
import LandingScene from "@/games/TowerClimbing/scenes/LandingScene";
import SpecialLandingScene from "@/games/TowerClimbing/scenes/SpecialLandingScene";
import WizardScene from "@/games/TowerClimbing/scenes/WizardScene";
import Settings from "@/games/TowerClimbing/scenes/Settings";
import CollectItem from "@/games/TowerClimbing/scenes/CollectItem";
import CollectWearableItem from "@/games/TowerClimbing/scenes/CollectWearableItem";
import ApiError from "@/games/TowerClimbing/scenes/ApiError";
import CompletionGift from "@/games/TowerClimbing/scenes/CompletionGift";
import WearItem from "@/games/TowerClimbing/scenes/WearItem";
import Confirmation from "@/games/TowerClimbing/scenes/Confirmation";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      PlayScene,
      WizardScene,
      LandingScene,
      SpecialLandingScene,
      Settings,
      CollectItem,
      CollectWearableItem,
      ApiError,
      CompletionGift,
      WearItem,
      Confirmation
    ],
  });
}

export default launch;
export { launch };
