import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import axios from "axios";
import moment from "moment";
import i18n from "@/plugins/i18n";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.timestampReset = data.timestampReset;
    (this.colors = data.colors),
      (this.env = data.env),
      (this.schoolYear = data.schoolYear),
      this.classroomMonth = data.classroomMonth,
      this.isHasSpecialItem = data.isHasSpecialItem
  }

  selectedOption = -1;
  avatarObject = [];
  avatarX = 240;
  avatarY = 730;
  avatarJY = 590;
  tileTween;
  tileTweenBorder;
  currentColor;
  nextColor;
  tilesGroup;
  isClicked = false;
  timerInterval;
  rechargebale = false;
  footprintTweens;
  _mark_correct;
  create() {
    //  this.cameras.main.fadeIn(1000, 0, 0, 0)
    this.avatarY = 730;
    this.avatarJY = 590;
    this.isClicked = false;

    this._backgroundImage = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);

    this.blustartBoard = this.add
      .image(420, 95, "imgScorePanel")
      .setDisplaySize(100, 50)
      .setDepth(2);

    this._bluestar = this.add
      .image(430, 82, "C000040")
      .setDisplaySize(30, 27)
      .setOrigin(0)
      .setDepth(2);

    this.starText = this.add
      .text(390, 93, "" + this.blueStar, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setFontStyle("bold")
      .setDepth(2);

    this.footprintBoard = this.add
      .image(420, 155, "imgScorePanel")
      .setDisplaySize(100, 60)
      .setDepth(2);

    this._footprint = this.add
      .image(430, 135, "footprint")
      .setDisplaySize(30, 30)
      .setOrigin(0)
      .setDepth(2);

    this.footprintText = this.add
      .text(390, 148, "" + this.ladder, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setFontStyle("bold")
      .setDepth(2);

    this.resetText = this.add
      .text(380, 172, `${i18n.t("string.resetIn")} 00:00:00`, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 10,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);

    let textResetWidth = this.resetText.width;
    this.resetText.setX(420 - textResetWidth / 2);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.closeBtn = this.add
      .image(40, 40, "homeBtnImage")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

      this.resetBtn = this.add
      .image(440, 220, "resetBtnImage")
      .setScale(0.1)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.resetLevel, this);


    this.currentColor = this.colors[this.game.currentFloor % 10];
    this.nextColor = this.colors[(this.game.currentFloor + 1) % 10];
    //sounds
    // this.bgSound = this.sound.add("bgm");
    this.sfxAvatarJump = this.sound.add("sfxAvatarJump");
    this.sfxNextFloor = this.sound.add("sfxNextFloor");
    this.sfxTransition = this.sound.add("sfxTransition");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.sfxError = this.sound.add("sfxError");
    this.sfxEmoji = this.sound.add("sfxEmoji");
    // this.bgSound.setVolume(
    //   store.state.settings.data.audio.music *
    //     store.state.settings.data.audio.master
    // );
    // this.bgSound.loop = true;

    // this.bgSound.play();
    this.modelbg = this.add
      .rectangle(240, 377, 408, 100, 0xffffff)
      .setDepth(9)
      .setAlpha(0);
    //     const currentTime = moment();
    // const distance = Math.max(this.timestampReset - currentTime, 0);

    if (
      store.state.miniGame &&
      store.state.miniGame.data &&
      store.state.miniGame.data.score >= 0
    ) {
      this.collectStar();
    }
    if (this.timestampReset == null) {
      this.timestampReset = moment();
    }
    this.tick();
    this.timerInterval = setInterval(this.tick.bind(this), 1000);

    //     const duration = moment.duration(distance);

    // const hours = duration.hours().toString().padStart(2, '0');
    // const minutes = duration.minutes().toString().padStart(2, '0');
    // const seconds = duration.seconds().toString().padStart(2, '0');

    // this.resetText.setText(`${hours}:${minutes}:${seconds}`);

    // this.spriteLand = this.add
    //   .sprite(0, 0, "spriteLand")
    //   .setScale(0.4)
    //   .setAlpha(0)
    //   .setDepth(5);
    // this.anims.create({
    //   key: "avatarLand",
    //   frames: this.anims.generateFrameNumbers("spriteLand", {
    //     frames: [0, 1, 2, 3],
    //   }),
    //   frameRate: 10,
    // });

    this.spriteJump = this.add
      .sprite(0, 0, "spriteJump")
      .setScale(2)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarJump",
      frames: this.anims.generateFrameNumbers("spriteJump", {
        frames: [0, 1, 2, 3],
      }),
      frameRate: 5,
    });
    this.tilesGroup = this.add.group();
    this._platformBig_002_end = this.add
      .image(65, -383 - 253 - 140 * 6, "platformBig_001")
      .setDisplaySize(350, 583)
      .setOrigin(0)
      .setTint(0x666666);
    this.tilesGroup.add(this._platformBig_002_end);
    this._arrowRight_2 = this.add
      .image(85, -383 - 400 - 240 - 35, "arrowRight")
      .setDisplaySize(75, 75)
      .setOrigin(0)
      .setTint(0x666666);
    this.tilesGroup.add(this._arrowRight_2);
    this._platform_002_magic = this.add
      .image(115, -253 - 140 * 5, "platform_001")
      .setDisplaySize(250, 150)
      .setOrigin(0)
      .setTint(0x666666)
      .setDepth(2);
    this.tilesGroup.add(this._platform_002_magic);
    this._hamochi_wizard_2 = this.add
      .image(250, -253 - 140 * 4 - 200, "hamochiWizard")
      .setDisplaySize(100, 100)
      .setOrigin(0)
      .setTint(0x666666)
      .setDepth(2);
    this.tilesGroup.add(this._hamochi_wizard_2);
    for (var j = 0; j < this.questions.length; j++) {
      this["_platform_002_" + j] = this.add
        .image(115, -253 - 140 * (4 - j), "platform_001")
        .setDisplaySize(250, 150)
        .setOrigin(0)
        .setDepth(this.questions.length - j + 1)
        .setTint(0x666666);
      this.tilesGroup.add(this["_platform_002_" + j]);

      if (this.game.currentTile - 1 < j) {
        this["_platform_002_" + j].setTint(0x666666);
      }
    }

    this._platformBig_001_end = this.add
      .image(65, -383, "platformBig_001")
      .setDisplaySize(350, 583)
      .setOrigin(0)
      .setTint(0x666666);

    this._iconPOUNCH = this.add
      .image(290, -5, "iconPOUNCH")
      .setDisplaySize(100, 100)
      .setOrigin(0)
      .setTint(0x666666);
    this.tilesGroup.add(this._iconPOUNCH);

    this.tilesGroup.add(this._platformBig_001_end);

    this.floorText_1 = this.add
      .text(0, 25, i18n.t("miniGames.towerClimbing.floor"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#FFFFFF",
      })
      .setOrigin(0, 0.5)
      .setDepth(5)
      .setAlpha(0.5)
      .setShadow(-5, 5, "rgba(0,0,0,0.5)", 0);
    let textFloortWidth = this.floorText_1.width;
    this.floorText_1.setX(240 - textFloortWidth / 2);
    this.floorTextNumber_1 = this.add
      .text(0, 70, this.game.currentFloor + 1 + "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 70,
        color: "#FFFFFF",
      })
      .setOrigin(0, 0.5)
      .setDepth(5)
      .setAlpha(0.5)
      .setShadow(-5, 5, "rgba(0,0,0,0.5)", 0);

    let textWidth = this.floorTextNumber_1.width;
    this.floorTextNumber_1.setX(240 - textWidth / 2);

    this.tilesGroup.add(this.floorText_1);
    this.tilesGroup.add(this.floorTextNumber_1);
    this._arrowRight_1 = this.add
      .image(85, 35, "arrowRight")
      .setDisplaySize(75, 75)
      .setOrigin(0)
      .setTint(0x666666);
    this.tilesGroup.add(this._arrowRight_1);

    this._arrowLeft_2 = this.add
      .image(330, -385, "arrowLeft")
      .setDisplaySize(75, 75)
      .setOrigin(0);
    this.tilesGroup.add(this._arrowLeft_2);
    this._platform_001_magic = this.add
      .image(115, 140, "platform_001")
      .setDisplaySize(250, 150)
      .setOrigin(0)
      .setTint(0x666666)
      .setDepth(2);

    this.tilesGroup.add(this._platform_001_magic);

    this._hamochi_wizard_1 = this.add
      .image(250, 80, "hamochiWizard")
      .setDisplaySize(100, 120)
      .setOrigin(0)
      .setTint(0x666666)
      .setDepth(2);
    this.tilesGroup.add(this._hamochi_wizard_1);

    //   this._platform_001_2 = this.add
    //   .image(480 / 2 - 125, 420, "platform_001")
    //   .setDisplaySize(250, 150)
    //   .setOrigin(0)
    //   .setTint(0x666666);

    // this._platform_001_1 = this.add
    //   .image(480 / 2 - 125, 560, "platform_001")
    //   .setDisplaySize(250, 150)
    //   .setOrigin(0)
    //   .setInteractive({ useHandCursor: true })
    //   .on("pointerdown", this.jumpToNext, this);

    for (var i = 0; i < this.questions.length; i++) {
      this["_platform_001_" + i] = this.add
        .image(115, 140 * (4 - i), "platform_001")
        .setDisplaySize(250, 150)
        .setOrigin(0)
        .setDepth(this.questions.length - i + 1)
        .setTint("0x" + this.currentColor);

      this["_platform_icon_" + i] = this.add
        .image(250, 140 * (4 - i) - 40, "icon" + this.questions[i].gameCode)
        .setDisplaySize(100, 100)
        .setOrigin(0)
        .setDepth(this.questions.length - i + 2);

      this.tilesGroup.add(this["_platform_001_" + i]);
      this.tilesGroup.add(this["_platform_icon_" + i]);
      if (this.game.currentTile - 1 == i) {
        this._platform_small_border_1 = this.add
          .image(115, 140 * (4 - i), "platformSmallBorder")
          .setDisplaySize(250, 150)
          .setOrigin(0)
          .setDepth(this.questions.length - i + 1);

        if (this.game.currentTile != 1) {
          this.avatarY = 140 * (5 - i);
          this.avatarJY = this.avatarY - 140;
        }
        this.tileTweenBorder = this.tweens.add({
          targets: this._platform_small_border_1,
          y: 140 * (4 - i) - 10,
          duration: 1500,
          yoyo: true,
          repeat: -1,
        });

        this.tileTween = this.tweens.add({
          targets: this["_platform_001_" + i],
          y: 140 * (4 - i) - 10,
          duration: 1500,
          yoyo: true,
          repeat: -1,
        });

        this.iconTween = this.tweens.add({
          targets: this["_platform_icon_" + i],
          y: 140 * (4 - i) - 40 - 10,
          duration: 1500,
          yoyo: true,
          repeat: -1,
        });

        this["_platform_001_" + i]
          .setInteractive({ useHandCursor: true })
          .on(
            "pointerdown",
            this.spendLadder.bind(this, this.questions[i]),
            this
          );
      }

      if (this.game.currentTile - 1 < i) {
        this["_platform_001_" + i].setTint(0x666666);
        this["_platform_icon_" + i].setTint(0x666666);
      }
    }

    if (this.game.currentTile == 4) {
      this._platform_small_border_1 = this.add
        .image(115, 140, "platformSmallBorder")
        .setDisplaySize(250, 150)
        .setOrigin(0);

      this.tileTweenBorder = this.tweens.add({
        targets: this._platform_small_border_1,
        y: 130,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });

      this.tileTween = this.tweens.add({
        targets: this._platform_001_magic,
        y: 130,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });

      this.iconTween = this.tweens.add({
        targets: this._hamochi_wizard_1,
        y: 70,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });
      this.avatarY = 280;
      this.avatarJY = 140;
      this._platform_001_magic
        .setTint("0x" + this.currentColor)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.spendLadder, this);
      this._hamochi_wizard_1.clearTint();
    } else if (this.game.currentTile == 5) {
      this._platformBig_001_end
        .setTint("0x" + this.nextColor)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.spendLadder, this);
      this._arrowRight_1.clearTint();
      this._iconPOUNCH.clearTint();
      this._hamochi_wizard_1
        .clearTint()
        .on("pointerdown", this.spendLadder, this);
      this._platform_001_magic.setTint("0x" + this.currentColor);
      this._platform_big_border = this.add
        .image(480 / 2 - 175, -390, "platformBigBorder")
        .setDisplaySize(350, 583)
        .setOrigin(0)
        .setDepth(1);

      this.tilesGroup.add(this._platform_big_border);
      this.tileTween = this.tweens.add({
        targets: [this._platformBig_001_end],
        y: -400,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });

      this.tileTweenBorder = this.tweens.add({
        targets: this._platform_big_border,
        y: -405,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });

      this.floorTextNumberTween = this.tweens.add({
        targets: this.floorTextNumber_1,
        y: 50,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });
      this.floorTextTween = this.tweens.add({
        targets: this.floorText_1,
        y: 5,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });

      this.arrowTween = this.tweens.add({
        targets: this._arrowRight_1,
        y: 20,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });
      this.iconTween = this.tweens.add({
        targets: this._iconPOUNCH,
        y: -20,
        duration: 1500,
        yoyo: true,
        repeat: -1,
      });

      this.avatarY = 140;
      this.avatarJY = 0;
    }

    // this._platform_small_border_1 = this.add
    //   .image(480 / 2 - 125, 560, "platformSmallBorder")
    //   .setDisplaySize(250, 150)
    //   .setOrigin(0);

    // this.tileTween = this.tweens.add({
    //   targets: this._platform_001_1,
    //   y: 550,
    //   duration: 1500,
    //   yoyo: true,
    //   repeat: -1,
    // });

    // this.tileTweenBorder = this.tweens.add({
    //   targets: this._platform_small_border_1,
    //   y: 550,
    //   duration: 1500,
    //   yoyo: true,
    //   repeat: -1,
    // });
    // this._count_bubble = this.add
    //   .image(this.avatarX - 120, this.avatarY - 20, "countBubble")
    //   .setDisplaySize(25, 22)
    //   .setOrigin(0)
    //   .setDepth(7);

    // this.tweens.add({
    //   targets: this._count_bubble,
    //   scaleY: 1,
    //   scaleX: 1,
    //   duration: 200,
    //   ease: "Power1",
    //   onComplete: () => {
    //     this._icon_foot = this.add
    //       .image(this.avatarX - 92, this.avatarY - 10, "footIcon")
    //       .setDisplaySize(25, 22)
    //       .setOrigin(0)
    //       .setDepth(7);
    //     // this.bubbleText = this.add
    //     //   .text(this.avatarX - 105, this.avatarY, this.ladder + "", {
    //     //     fontFamily: "Sassoon, sans-serif",
    //     //     fontSize: 18,
    //     //     color: "#492a12",
    //     //   })
    //     //   .setOrigin(0, 0.5)
    //     //   .setDepth(7);
    //   },
    // });

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, this.avatarY, key)
              .setScale(0.2)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, this.avatarY, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.22)
              .setAlpha(0.3)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, this.avatarY, key)
              .setScale(0.2)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }
    //this.tilesGroup.add(this.avatarObject)
    // this.time.delayedCall(1000, () => {
    //   this.spriteJump.y = this.avatarObject[0].y;
    //   this.spriteJump.setAlpha(1);
    //   this.spriteJump.play("avatarJump");

    //   this.tweens.add({
    //     targets: this.avatarObject,
    //     scaleY: 0.22,
    //     y: "+=10",
    //     ease: "Power1",
    //     duration: 100,
    //     yoyo: true,
    //     onStart: () => {
    //       if (this.avatarMask) {
    //         this.avatarMask.destroy();
    //       }
    //       this.avatarMask = this.add
    //         .image(this.avatarObject[0].x, this.avatarObject[0].y, "push")
    //         .setScale(0.25)
    //         .setDepth(6);
    //     },
    //     onComplete: () => {
    //       this.tweens.add({
    //         targets: this.avatarObject,
    //         y: "-=400",
    //         ease: "Power1",
    //         duration: 700,
    //         yoyo: true,

    //       });
    //     },
    //   });
    // });
    this.avatarBounceTweens = this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.23,
      y: "-=9",
      scaleX: 0.19,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
    this._platformBig_001_start = this.add
      .image(65, 695, "platformBig_001")
      .setDisplaySize(350, 583)
      .setOrigin(0)
      .setTint("0x" + this.currentColor);

    this._arrowLeft_1 = this.add
      .image(330, 700, "arrowLeft")
      .setDisplaySize(75, 75)
      .setOrigin(0);
    this.tilesGroup.add(this._platformBig_001_start);
    this.tilesGroup.add(this._arrowLeft_1);
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "LandingScene",
    });
  }
  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    router.push({
      name: "PageExploreLanding",
    });
  }
  openCollectItem() {
    this.scene.pause();
    this.scene.launch("CollectItem", {
      type: "landingFootprint",
      sceneName: "LandingScene",
      itemCount: "X5",
      itemKey: "footprint",
      avatar: this.avatar,
      ladder: this.ladder,
      blueStar: this.blueStar,
      game: this.game,
      end: this.end,
      wizard: this.wizard,
      questions: this.questions,
      colors: this.colors,
      env: this.env,
      timestampReset: this.timestampReset,
      schoolYear: this.schoolYear,
      classroomMonth: this.classroomMonth,
      isHasSpecialItem:this.isHasSpecialItem,
    });
  }
  openCompletionGift() {
    this.scene.pause();
    this.scene.launch("CompletionGift", {
      sceneName: "LandingScene",
      avatar: this.avatar,
      ladder: this.ladder,
      blueStar: this.blueStar,
      game: this.game,
      end: this.end,
      wizard: this.wizard,
      questions: this.questions,
      colors: this.colors,
      env: this.env,
      timestampReset: this.timestampReset,
      schoolYear: this.schoolYear,
      classroomMonth: this.classroomMonth,
      isHasSpecialItem:this.isHasSpecialItem,
    });
  }
  openApiError() {
    this.scene.pause();
    this.scene.launch("ApiError", {
      sceneName: "LandingScene",
      mgs: "Not enough footprints.",
    });
  }
  spendLadder(tile) {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    if (this.isClicked == false) {
      this.isClicked = true;
      if (this.ladder > 0) {
        this.ladder = this.ladder - 1;
        this.footprintText.setText(this.ladder + "");
        this._icon_foot_spend = this.add
          .image(this.avatarX - 20, this.avatarY, "footSpend")
          .setDisplaySize(60, 38)
          .setOrigin(0)
          .setDepth(7);
        this.time.delayedCall(700, () => {
          this.jumpToNext(tile);
        });
        this.tweens.add({
          targets: [this._icon_foot_spend],
          y: this.avatarY - 100,
          alpha: 0,
          duration: 3000,
          ease: "Sine.easeOut",
        });
      } else {
        this.sfxError.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxError.play();
        this.showError(i18n.t("miniGames.towerClimbing.footprintError"));
        this.speechRound = this.add
          .image(this.avatarObject[0].x, this.avatarY - 80, "speechRound")
          .setScale(0.0)
          .setDepth(7);

        this.tweens.add({
          targets: [this.speechRound],
          scaleX: 0.35,
          scaleY: 0.35,
          duration: 300,
          ease: "Sine.easeOut",
          onComplete: () => {
            this.time.delayedCall(400, () => {
              this.avatarMask = this.add
                .image(this.avatarObject[0].x, this.avatarY, "maskSad")
                .setScale(0.2)
                .setDepth(6);
            });

            this.time.delayedCall(1000, () => {
              this.avatarMask.destroy();
              this.speechRound.setVisible(false);
              this.isClicked = false;
            });
          },
        });
        //this._count_bubble.setTint(0xff0000);
        //  this.openApiError()
      }
    }
  }
  showError(mgs) {
    const words = mgs.split(" ");
    const lines = [];

    while (words.length > 0) {
      lines.push(words.splice(0, 3).join(" "));
    }

    const centerX = this.cameras.main.centerX;
    const centerY = this.cameras.main.centerY - 50;
    this.tweens.add({
      targets: this.modelbg,
      alpha: 0.5,
      duration: 200,
      ease: "Sine.easeInOut",
    });
    lines.map((line, index) => {
      const text = this.add
        .text(centerX, centerY + (index - (lines.length - 1) / 2) * 30, line, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          fill: "#ffffff",
          stroke: "#D50000",
          strokeThickness: 2,
        })
        .setDepth(10);

      // Center the text
      text.setOrigin(0.5, 0.5);

      this.tweens.add({
        targets: text,
        scaleX: 1.5,
        scaleY: 1.5,
        duration: 200,
        ease: "Sine.easeInOut",
        onComplete: () => {
          this.tweens.add({
            targets: this.modelbg,
            alpha: 0,
            duration: 200,
            delay: 1000,
            ease: "Sine.easeInOut",
          });
          this.tweens.add({
            targets: text,
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: "Sine.easeInOut",
            delay: 1000,
          });
        },
      });
      return text;
    });
  }
  jumpToNext(tile) {
    this.avatarBounceTweens.stop();
    this.sfxAvatarJump.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxAvatarJump.play();
    // this._count_bubble.setVisible(false);

    // this._icon_foot.setVisible(false);
    // this.input.off('pointerdown', this.jumpToNext, this);
    // Define the target position (center of the background)
    const centerX = this.avatarX;
    const centerY = this.avatarJY;
    this.spriteJump.y = this.avatarObject[0].y;
    this.spriteJump.setAlpha(1);
    this.spriteJump.play("avatarJump");
    // Create a tween to move the ball to the center of the background
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.17,
      y: "+=9",
      duration: 100, // Duration of the tween in milliseconds
      ease: "Power1", // Easing function (you can adjust this)
      yoyo: true,
      // onStart: () => {
      //   if (this.avatarMask) {
      //     this.avatarMask.destroy();
      //   }
      //   this.avatarMask = this.add
      //     .image(this.avatarObject[0].x, this.avatarY+10, "maskGrimace")
      //     .setScale(0.25)
      //     .setDepth(6);
      // },
      onComplete: () => {
        // if (this.avatarMask) {
        //   this.avatarMask.destroy();
        // }
        this.tweens.add({
          targets: this.avatarObject,
          y: "-=200",
          ease: "Power1",
          duration: 400,
          onComplete: () => {
            if (this.tileTween) this.tileTween.remove();
            if (this.arrowTween) this.arrowTween.remove();
            if (this.iconTween) this.iconTween.remove();
            if (this.floorTextTween) this.floorTextTween.remove();
            if (this.floorTextNumberTween) this.floorTextNumberTween.remove();
            if (this._platform_big_border) this._platform_big_border.destroy();
            if (this.tileTweenBorder) {
              this.tileTweenBorder.remove();
            }
            // this._platform_001_1.off("pointerdown", this.jumpToNext, this);
            // this.tileTween = this.tweens.add({
            //   targets: this._platform_001_2,
            //   y: 410,
            //   duration: 1500,
            //   yoyo: true,
            //   repeat: -1,
            // });
            // this._platform_small_border_1.setVisible(false);
            // this._platform_small_border_2 = this.add
            //   .image(480 / 2 - 125, 420, "platformSmallBorder")
            //   .setDisplaySize(250, 150)
            //   .setOrigin(0);

            // this.tileTweenBorder = this.tweens.add({
            //   targets: this._platform_small_border_2,
            //   y: 410,
            //   duration: 1500,
            //   yoyo: true,
            //   repeat: -1,
            // });

            this.tweens.add({
              targets: this.avatarObject,
              x: centerX,
              y: centerY,
              ease: "Power1",
              duration: 400,
              onComplete: () => {
                this.spriteJump.visible = false;
                // this.spriteLand.y = this.avatarObject[0].y;
                // this.spriteLand.setAlpha(1);
                this.tweens.add({
                  targets: this.avatarObject,
                  scaleY: 0.2,
                  y: "+=9",
                  ease: "Power1",
                  duration: 200,
                  yoyo: true,
                  onComplete: () => {
                    this.sfxEmoji.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.sfxEmoji.play();
                    this.speech = this.add
                      .image(centerX + 50, centerY - 40, "speech")
                      .setScale(0.0)
                      .setDepth(7);

                    this.mark = this.add
                      .image(centerX + 50, centerY - 40, "excl")
                      .setScale(0.0)
                      .setDepth(7);
                    // if (this.avatarMask) {
                    //   this.avatarMask.destroy();
                    // }
                    this.tweens.add({
                      targets: [this.mark],
                      scaleX: 0.25,
                      scaleY: 0.25,
                      duration: 300,
                      ease: "Sine.easeOut",
                    });
                    this.tweens.add({
                      targets: [this.speech],
                      scaleX: 0.35,
                      scaleY: 0.35,
                      duration: 300,
                      ease: "Sine.easeOut",
                      onComplete: () => {
                        this.time.delayedCall(500, () => {
                          // this.spriteLand.visible = false;
                          this.time.delayedCall(700, () => {
                            if (this.game.currentTile < 4) {
                              this.set(tile);
                            } else if (this.game.currentTile == 4) {
                              this.goWizard();
                            } else {
                              // this.scene.pause();
                              // this.scene.launch("CompletionGift", {
                              //   sceneName: "LandingScene",
                              //   avatar: this.avatar,
                              //   ladder: this.ladder,
                              //   blueStar: this.blueStar,
                              //   game: this.game,
                              //   end: this.end,
                              //   wizard: this.wizard,
                              //   questions: this.questions,
                              //   colors: this.colors,
                              //   env: this.env,
                              //   timestampReset: this.timestampReset,
                              //   schoolYear: this.schoolYear,
                              //   classroomMonth: this.classroomMonth,
                              //   isHasSpecialItem:this.isHasSpecialItem,
                              // });
                              this.game.currentTile =
                                this.game.currentTile == 5
                                  ? 1
                                  : this.game.currentTile + 1;
                              this.game.currentFloor =
                                this.game.currentTile == 1
                                  ? this.game.currentFloor + 1
                                  : this.game.currentFloor;
                              this.sfxNextFloor.setVolume(
                                store.state.settings.data.audio.sfx *
                                  store.state.settings.data.audio.master
                              );
                              this.sfxNextFloor.play();
                              let token =
                              store.state.auth.data.Session.token;
                            const headers = {
                              Authorization: `Bearer ${token}`,
                            };

                            axios
                              .post(
                                `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/footprint/spend`,
                                { quantity: 1 },
                                { headers }
                              )
                              .then(() => {})
                              .catch((error) => {
                                // Handle any errors that occur during the API call
                                console.error("Error:", error);
                              });
                            axios
                              .post(
                                `${this.env.VUE_APP_URL_API_GAME}/api/v2/en/game/dailyGame/move`,
                                { schoolYear: this.schoolYear,classroomMonth: this.classroomMonth },
                                { headers }
                              )
                              .then((response) => {
                                if(store.state.auth.data.Player.userType != "adult"){
                                  this.submitLog()
                                }
                                // Process the data returned from the API
                                this.questions =
                                  response.data.Data.Question.reverse();
                                  this.scene.pause();
                                  this.scene.launch("CompletionGift", {
                                    sceneName: "LandingScene",
                                    avatar: this.avatar,
                                    ladder: this.ladder,
                                    blueStar: this.blueStar,
                                    game: this.game,
                                    end: this.end,
                                    wizard: this.wizard,
                                    questions: this.questions,
                                    colors: this.colors,
                                    env: this.env,
                                    timestampReset: this.timestampReset,
                                    schoolYear: this.schoolYear,
                                    classroomMonth: this.classroomMonth,
                                    isHasSpecialItem:this.isHasSpecialItem,
                                  });
                                  this.tweens.add({
                                    targets: this.tilesGroup.getChildren(),
                                    y: "+=1093", // Y position at the bottom
                                    duration: 1000, // Duration of the tween in milliseconds
                                    ease: "Linear", // Easing function (linear for constant speed)
                                  });

                                  this.tweens.add({
                                    targets: this.avatarObject,
                                    y: "+=1093", // Y position at the bottom
                                    duration: 1000, // Duration of the tween in milliseconds
                                    ease: "Linear",
                                    onComplete: () => {
                                      this.tweens.add({
                                        targets: this.avatarObject,
                                        y: "-=350", // Y position at the bottom
                                        duration: 1000, // Duration of the tween in milliseconds
                                        ease: "Linear",
                                        onComplete: () => {
                                          this.scene.start("LandingScene", {
                                            avatar: this.avatar,
                                            ladder: this.ladder,
                                            blueStar: this.blueStar,
                                            game: this.game,
                                            end: this.end,
                                            wizard: this.wizard,
                                            colors: this.colors,
                                            questions: this.questions,
                                            env: this.env,
                                            timestampReset: this.timestampReset,
                                            schoolYear: this.schoolYear,
                                            classroomMonth: this.classroomMonth,
                                            isHasSpecialItem:this.isHasSpecialItem,
                                          });
                                        },
                                      });
                                    },
                                  });
                                
                              })
                              .catch((error) => {
                                // Handle any errors that occur during the API call
                                console.error("Error:", error);
                              });
                            }
                          });
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }
  set(tile) {
    // this.scene.start("PlayScene", {
    //   avatar: this.avatar,
    //   ladder: this.ladder,
    //   blueStar: this.blueStar,
    //   game: this.game,
    //   end: this.end,
    //   wizard: this.wizard,
    //   colors: this.colors,
    //   questions: this.questions,
    //   env: this.env,
    // });
    this.sfxTransition.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxTransition.play();
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    let game_cache_data = {};
    game_cache_data.q = tile.questionKey;
    game_cache_data.c = tile.chapterKey;
    game_cache_data.g = tile.gameCode;

    store.commit("updateMiniGame", game_cache_data);
    if (store.state.settings.data.debug) {
      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageExploreDailyGameDetails",
        });
      });
    } else {
      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageExploreDailyGamePlay",
        });
      });
    }
  }
  updateRemaining(distance) {
    //  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");
    this.resetText.setText(
      `${i18n.t("string.resetIn")} ${hours}:${minutes}:${seconds}`
    );
  }
  tick() {
    // if (this.footprintTweens) {
    //   this.footprintTweens.remove();
    // }

    const currentTime = new Date();
    const distance = Math.max(this.timestampReset - currentTime, 0);

    if (distance === 0) {
      clearInterval(this.timerInterval);

      this.rechargebale = true;
      this.resetText.setText(`${i18n.t("string.resetIn")} 00:00:00`);
      this.time.delayedCall(1000, () => {
        this.openCollectItem();
      });

      // this._footprint_border
      //   .setTexture("footprintBorderBlue")
      //   .setInteractive({ useHandCursor: true })
      //   .on("pointerdown", this.collectFoot, this);
      // this.footprintTweens = this.tweens.add({
      //   targets: this._footprint_border,
      //   scaleX: 0.58,
      //   scaleY: 0.58,
      //   ease: "Linear",
      //   duration: 700,
      //   yoyo: true,
      //   repeat: -1,
      // });
    } else {
      this.updateRemaining(distance);
    }
  }
  collectStar() {
    let score = store.state.miniGame.data.score;
    let star = 0;
    if(store.state.miniGame.data.g == 'BATTLE'){
      star = store.state.miniGame.data.star
    }else{
      if (score >= 150 && score < 200) {
        star = 1;
      } else if (score >= 200 && score < 250) {
        star = 2;
      } else if (score >= 250) {
        star = 3;
      } else {
        star = 0;
      }  
    }
    
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/item/updateMany`,
        {
          data:
            '[{"action":"add","itemKey":"C000040","quantity":' + star + "}]",
        },
        { headers }
      )
      .then((response) => {
        // Process the data returned from the API
        // this.time.delayedCall(1000, () => {
        //   _mark_correct.setVisible(false);
        // });
        this.blueStar = response.data[0].quantity;
        store.dispatch("resetMiniGame");
        this.scene.pause();
        this.scene.launch("CollectItem", {
          type: "landingBlueStar",
          sceneName: "LandingScene",
          itemCount: "+" + star,
          itemKey: "C000040",
          avatar: this.avatar,
          ladder: this.ladder,
          blueStar: this.blueStar,
          game: this.game,
          end: this.end,
          wizard: this.wizard,
          questions: this.questions,
          colors: this.colors,
          env: this.env,
          timestampReset: this.timestampReset,
          schoolYear: this.schoolYear,
          classroomMonth: this.classroomMonth,
          isHasSpecialItem:this.isHasSpecialItem,
        });
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  submitLog() {
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_LOG}/api/v1/en/dailyGameProgress/add`,
        {
          institutionId: store.state.auth.data.Institution?store.state.auth.data.Institution.id : null,
          classroomId: store.state.auth.data.Classroom?store.state.auth.data.Classroom.id : null,
          studentId: store.state.auth.data.Classroom? store.state.auth.data.User.id : null,
          userId: store.state.auth.data.Classroom?null:store.state.auth.data.User.id,
          schoolYear: this.schoolYear,
          classroomMonth: this.classroomMonth,
          mochiId: store.state.auth.data.Player.mochiId,
          currentFloor: this.game.currentFloor,
          currentTile: this.game.currentTile,
        },
        { headers }
      )
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  resetLevel(){
    this.scene.pause();
    this.scene.launch("Confirmation", {
      sceneName: "LandingScene",
      env: this.env,
      classroomMonth: this.classroomMonth,
      schoolYear: this.schoolYear
    });
  }
  resetLevel1() {
     this.resetBtn
      .off("pointerdown", this.resetLevel, this)
      .removeInteractive();
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v2/en/game/dailyGame/reset`,
        { schoolYear: this.schoolYear, classroomMonth: this.classroomMonth },
        { headers }
      ) .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  collectFoot() {
    // this._footprint_border
    //   .off("pointerdown", this.collectFoot, this)
    //   .removeInteractive();
    // var _mark_correct = this.add
    //   .image(50, 320, "markCorrect")
    //   .setDisplaySize(30, 30)
    //   .setOrigin(0)
    //   .setDepth(2);
    // this._footprint_border.setTexture("footprintBorder");
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/footprint/reset`,
        {},
        { headers }
      )
      .then((response) => {
        // Process the data returned from the API
        // this.time.delayedCall(1000, () => {
        //   _mark_correct.setVisible(false);
        // });
        this.ladder = response.data.Footprint.quantity;
        this.footprintText.setText(this.ladder + "");
        this.timestampReset = moment(response.data.TimestampReset);

        this.tick();
        this.timerInterval = setInterval(this.tick.bind(this), 1000);
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  goWizard() {
    //this.scene.stop("LandingScene");
    this.sfxTransition.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxTransition.play();
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.time.delayedCall(1000, () => {
      this.scene.start("WizardScene", {
        avatar: this.avatar,
        ladder: this.ladder,
        blueStar: this.blueStar,
        game: this.game,
        end: this.end,
        wizard: this.wizard,
        colors: this.colors,
        questions: this.questions,
        env: this.env,
        timestampReset: this.timestampReset,
        schoolYear: this.schoolYear,
        classroomMonth: this.classroomMonth,
        isHasSpecialItem:this.isHasSpecialItem,
      });
    });
  }

  update() {
    //   console.log(this.remainingTime)
    //   if (this.remainingTime > 0) {
    //     this.resetText.setText(`00:00:00`);
    //   }else{

    //     const duration = moment.duration(this.remainingTime, 'seconds');
    //     const hours = duration.hours().toString().padStart(2, '0');
    //     const minutes = duration.minutes().toString().padStart(2, '0');
    //     const seconds = duration.seconds().toString().padStart(2, '0');

    // this.resetText.setText(`${hours}:${minutes}:${seconds}`);
    //   }

    // // Decrement remaining time every second
    // if (this.remainingTime > 0) {
    //   this.remainingTime--;
    // }
    if (this.avatarObject[0]) {
      //this.spriteLand.x = this.avatarObject[0].x;
      this.spriteJump.x = this.avatarObject[0].x;
    }
    // this.bgSound.setVolume(
    //   store.state.settings.data.audio.music *
    //     store.state.settings.data.audio.master
    // );
  }
}
