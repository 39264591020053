import { Scene } from "phaser";
import store from "@/store/index.js";
import i18n from "@/plugins/i18n";
// import router from "@/router";
import axios from "axios";

export default class Confirmation extends Scene {
  constructor() {
    super({ key: "Confirmation" });
  }

  init(data) {
    this.sceneName = data.sceneName;
    this.env = data.env;
    this.classroomMonth =data.classroomMonth;
this.schoolYear = data.schoolYear
  }

  preload() {
    //
  }

  create() {
    var continueBtn;
    var continueTxt;
    var closeBtn;

    // Sounds
    this.closeSound = this.sound.add("close");
    this.simpleClickSound = this.sound.add("simpleClick");

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.7);
    this.add.image(240, 350, "settingPanel").setScale(1.1, 0.8);

    closeBtn = this.add.image(370, 260, "closeBtnImage").setScale(0.3);
    closeBtn.on("pointerdown", clickClose, this);
    closeBtn.setInteractive({ useHandCursor: true });


    this.add
      .text(240, 340, i18n.t("miniGames.general.restConfirmationTxt"), {
        fontSize: "20px",
        wordWrap: { width: 250 },
        fontFamily: "Sassoon, sans-serif",
        color: "#000000",
        align: "center",
      })
      .setOrigin(0.5);

    continueBtn = this.add.image(240, 425, "optionButton") .setTint("0xFF0000").setScale(0.35);
    continueTxt = this.add
      .text(240, 425, i18n.t("action.reset"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 60,
        color: "#FFFFFF",
        align: "center",
      })
      .setScale(0.35);
      continueBtn.on("pointerdown", resetLevel, this);
    continueTxt.setOrigin(0.5);
    continueTxt.setPadding(20);
    continueBtn.setInteractive({ useHandCursor: true });

    function resetLevel() {
      continueBtn
       .off("pointerdown", resetLevel, this)
       .removeInteractive();
     let token = store.state.auth.data.Session.token;
     const headers = {
       Authorization: `Bearer ${token}`,
     };
     axios
       .post(
         `${this.env.VUE_APP_URL_API_GAME}/api/v2/en/game/dailyGame/reset`,
         { schoolYear: this.schoolYear, classroomMonth: this.classroomMonth },
         { headers }
       ) .then(() => {
        this.scene.stop("Confirmation");
         window.location.reload();
       })
       .catch((error) => {
         // Handle any errors that occur during the API call
         console.error("Error:", error);
       });
   }
    function clickClose() {
      
      this.closeSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.closeSound.play();
      this.scene.stop("Confirmation");
      this.scene.resume(this.sceneName);
    }

  
  }

  update() {
    //
  }
}
