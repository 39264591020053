import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import axios from "axios";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.colors =data.colors,
    this.env = data.env;
    this.timestampReset = data.timestampReset,
    this.schoolYear = data.schoolYear,
    this.classroomMonth = data.classroomMonth,
    this.specialItem = data.specialItem,
    this.isHasSpecialItem = data.isHasSpecialItem
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images

    // buttons
    this.load.image("closeBtnImage", "/assets/common/btn-close.png");
    this.load.image("homeBtnImage", "/assets/gameAssets/towerClimbing/b4.png");
    this.load.image("resetBtnImage", "/assets/gameAssets/towerClimbing/btn-reset.png");
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );
    this.load.image("okBtnImage", "/assets/common/btn-ok.png");
    // panel bg

    this.load.image("settingPanel", "/assets/common/panel-setting.png");
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );

    // others
    this.load.image("optionButton", "/assets/common/btn-option.png");
    this.load.image("markCorrect", "/assets/common/mark-correct.png");

    // images specific to the game
    this.load.image(
      "platformBig_001",
      "/assets/gameAssets/towerClimbing/platform_big_001.png"
    );
    this.load.image(
      "platform_001",
      "/assets/gameAssets/towerClimbing/platform_001.png"
    );
    this.load.image(
      "platformSmallBorder",
      "/assets/gameAssets/towerClimbing/platform_small_border.png"
    );
    this.load.image(
      "platformBigBorder",
      "/assets/gameAssets/towerClimbing/platform_big_border.png"
    );
    this.load.image(
      "arrowLeft",
      "/assets/gameAssets/towerClimbing/arrow_left.png"
    );
    this.load.image(
      "arrowRight",
      "/assets/gameAssets/towerClimbing/arrow_right.png"
    );
    this.load.image(
      "hamochiWizard",
      "/assets/gameAssets/towerClimbing/CB0010_hamochi_color_hamochi_wizard_1.png"
    );
    this.load.image(
      "hamochiSanta",
      "/assets/gameAssets/towerClimbing/santa_hamochi.png"
    );
    this.load.image(
      "imgBackground",
      "/assets/gameAssets/towerClimbing/background.png"
    );
    this.load.image(
      "imgBurstBackground",
      "/assets/gameAssets/towerClimbing/Burst.png"
    );
    this.load.image(
      "countBubble",
      "/assets/gameAssets/towerClimbing/count-bubble2.png"
    );
    this.load.image(
      "iconFRUIT",
      "/assets/gameAssets/towerClimbing/icon_FRUIT.png"
    );
    this.load.image(
      "iconCOOK",
      "/assets/gameAssets/towerClimbing/icon_COOK.png"
    );
    this.load.image(
      "iconGEMS",
      "/assets/gameAssets/towerClimbing/icon_GEMS.png"
    );
    this.load.image(
      "iconPOUNCH",
      "/assets/gameAssets/towerClimbing/icon_coin_pouch.png"
    );
    this.load.image(
      "iconBATTLE",
      "/assets/gameAssets/towerClimbing/icon_BATTLE.png"
    );
    this.load.image(
      "ladderIcon",
      "/assets/gameAssets/towerClimbing/C000030.png"
    );
    this.load.image(
      "footSpend",
      "/assets/gameAssets/towerClimbing/foot_spend.png"
    );
    this.load.image(
      "footIcon",
      "/assets/gameAssets/towerClimbing/footprint.png"
    );
    this.load.image("logo", "/assets/miningGame/mining_logo.png");

    this.load.image("speech", "/assets/gameAssets/towerClimbing/speech-bubble.png");
    this.load.image("speechRound", "/assets/gameAssets/towerClimbing/speech-bubble-round.png");
    this.load.image("itemFrame", "/assets/gameAssets/towerClimbing/item_frame.png");
    this.load.image("itemFrameBorder", "/assets/gameAssets/towerClimbing/item_frame_border.png");
    this.load.image("CHEST0020", "/assets/gameAssets/towerClimbing/CHEST0020.png");
    this.load.image("goldcoin", "/assets/gameAssets/towerClimbing/goldcoin.png");
    this.load.image("C000040", "/assets/gameAssets/towerClimbing/C000040.png");
    this.load.image("footprintBorder", "/assets/gameAssets/towerClimbing/footprint_border.png");
    this.load.image("footprint", "/assets/gameAssets/towerClimbing/footprint.png");
    this.load.image("footprintBorderBlue", "/assets/gameAssets/towerClimbing/footprint_blue1.png");
    this.load.image("imgCollectPanel", "/assets/gameAssets/towerClimbing/panel_collect.png");
    this.load.image("mochiRainbow", "/assets/gameAssets/towerClimbing/rainbow.png");
    this.load.image("mochiPlease", "/assets/gameAssets/towerClimbing/please.png");
    this.load.image("mochiFreeze", "/assets/gameAssets/towerClimbing/freeze.png");
    this.load.image("mochiSad", "/assets/gameAssets/towerClimbing/sad.png");
    this.load.image("hamochiWizardMagicShop", "/assets/gameAssets/towerClimbing/magic_shop.png");
    this.load.image("panelShop", "/assets/gameAssets/towerClimbing/panel_shop.png");
    this.load.image("loadingError", "/assets/gameAssets/towerClimbing/loadingError.png");
    this.load.image("completionGift1", "/assets/gameAssets/towerClimbing/completionGift1.png");
    this.load.image("completionGift2", "/assets/gameAssets/towerClimbing/completionGift2.png");

    //Speical
    this.load.image("S1002_H", "/assets/gameAssets/towerClimbing/S1002_H.png");
    //*** Load Sprite Sheets ***//
    this.load.spritesheet(
      "spriteLand",
      "/assets/foragingGame/sprite-land.png",
      {
        frameWidth: 600,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "spriteJump",
      "/assets/foragingGame/sprite-jump.png",
      {
        frameWidth: 32,
        frameHeight: 32,
      }
    );
    // Common
    this.load.image("excl", "/assets/common/mark-exclamation.png");
    // Specific
    this.load.spritesheet(
      "spriteJump",
      "/assets/foragingGame/sprite-jump.png",
      {
        frameWidth: 32,
        frameHeight: 32,
      }
    );

    //*** load audio files ***//
    // general sfxs
   
    this.load.audio(
      "simpleClick",
      "/assets/miningGame/sounds/simple_click.wav"
    );
   
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("close", "/assets/miningGame/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");

    // sfx specific to the game
    this.load.audio("bgm", "/assets/gameAssets/towerClimbing/sounds/bgm.mp3");
    this.load.audio("sfxNextFloor", "/assets/gameAssets/towerClimbing/sounds/nextFloor.mp3");
    this.load.audio("sfxAvatarJump", "/assets/gameAssets/towerClimbing/sounds/avatarJump.mp3");
    this.load.audio("sfxCollectItem", "/assets/gameAssets/towerClimbing/sounds/collectItem.mp3");
    this.load.audio("sfxPopUp", "/assets/gameAssets/towerClimbing/sounds/popUpReward.mp3");
    this.load.audio("sfxEmpty", "/assets/gameAssets/towerClimbing/sounds/empty.wav");
    this.load.audio("sfxClickSelect", "/assets/gameAssets/towerClimbing/sounds/clickSelect.mp3");
    this.load.audio("sfxTransition", "/assets/gameAssets/towerClimbing/sounds/transition.mp3");
    this.load.audio("sfxError", "/assets/gameAssets/towerClimbing/sounds/error.mp3");
    this.load.audio("sfxEmoji", "/assets/gameAssets/towerClimbing/sounds/emoji.wav");
    this.load.audio("sfxSurprise", "/assets/gameAssets/towerClimbing/sounds/surprise.mp3");

    //Load Special Item
    this.load.image('santaHat', this.specialItem);
    //*** Load Avatars ***//
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // // load avatar masks
    this.load.image("maskAngry", "/assets/gameAssets/common/masks/M0021.png");
    this.load.image("maskNice", "/assets/gameAssets/common/masks/M0012.png");
    this.load.image("maskFear", "/assets/gameAssets/common/masks/M0040.png");
    this.load.image("maskSad", "/assets/gameAssets/common/masks/M0031.png");
    this.load.image("maskHappy", "/assets/gameAssets/common/masks/M0013.png");
    this.load.image("maskMad", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskStun", "/assets/gameAssets/common/masks/M0060.png");
    this.load.image("maskWeary", "/assets/gameAssets/common/masks/M0030.png");
    this.load.image("maskYummy", "/assets/gameAssets/common/masks/M0050.png");
    this.load.image("maskGrimace", "/assets/gameAssets/common/masks/M0070.png");
    this.load.image("maskScold", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskWearyx", "/assets/gameAssets/common/masks/M0020.png");
    this.load.image("maskHappyx", "/assets/gameAssets/common/masks/M0011.png");
  }

  create() {
    let token =
    store.state.auth.data.Session.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios
    .post(
      `${this.env.VUE_APP_URL_API_GAME}/api/v2/en/game/dailyGame/visit`,
      { schoolYear: this.schoolYear, classroomMonth: this.classroomMonth },
      { headers }
    )
    .then((response) => {
      // Process the data returned from the API
      console.log(response.data);
      
      this.questions = response.data.Data.Question.reverse();
      this.bgSound = this.sound.add("bgm");
      this.bgSound.setVolume(
        store.state.settings.data.audio.music *
          store.state.settings.data.audio.master
      );
      this.bgSound.loop = true;
  
      this.bgSound.play();
      this.scene.start("LandingScene", {
        avatar: this.avatar,
        ladder: this.ladder,
        blueStar: this.blueStar,
        game: this.game,
        end: this.end,
        wizard: this.wizard,
        questions: this.questions,
        colors: this.colors,
        env: this.env,
        timestampReset:this.timestampReset,
        schoolYear: this.schoolYear,
        classroomMonth: this.classroomMonth,
        isHasSpecialItem:this.isHasSpecialItem
      });

      // this.scene.start("SpecialLandingScene", {
      //   avatar: this.avatar,
      //   ladder: this.ladder,
      //   blueStar: this.blueStar,
      //   game: this.game,
      //   end: this.end,
      //   wizard: this.wizard,
      //   questions: this.questions,
      //   colors: this.colors,
      //   env: this.env,
      //   timestampReset:this.timestampReset,
      //   schoolYear: this.schoolYear,
      //   isHasSpecialItem:this.isHasSpecialItem
      // });
    })
    .catch((error) => {
      // Handle any errors that occur during the API call
      console.error("Error:", error);
    });
    
  }
}
